import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl } from '@fortawesome/free-solid-svg-icons';

import './agenda.css';

const Agenda = () => {
  return (
    <div className="giswnauce__agenda">
        <div className="giswnauce__agenda-container">
            <span className="anchor-agenda" id="giswnauce_agenda"></span>
            <div className="agenda-container_header">
                <p><FontAwesomeIcon icon={faListUl} /></p><h1>Program Konferencji</h1>
            </div>
            <div className="agenda-container_subheader">
                <h2>26 września 2024 (Dzień 1)</h2>
            </div>
            <div className="agenda-container_description first_table">
                <table>
                    <thead>
                        <tr>
                            <th>Godziny</th>
                            <th>Element konferencji</th>
                            <th>Czas trwania</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>09:00 - 10:00</td>
                            <td>Rejestracja uczestników</td>
                            <td>1h</td>
                        </tr>
                        <tr>
                            <td>10:00 - 11:00</td>
                            <td>Uroczyste rozpoczęcie i wykład inauguracyjny</td>
                            <td>1h</td>
                        </tr>
                        <tr>
                            <td>11:00 - 11:30</td>
                            <td>Przerwa kawowa</td>
                            <td>30min</td>
                        </tr>
                        <tr>
                            <td>11:30 - 14:00</td>
                            <td>Sesja referatów I</td>
                            <td>2h 30min</td>
                        </tr>
                        <tr>
                            <td>14:00 - 14:45</td>
                            <td>Przerwa obiadowa</td>
                            <td>45min</td>
                        </tr>
                        <tr>
                            <td>14:45 - 17:00</td>
                            <td>Sesja referatów II</td>
                            <td>2h 15min</td>
                        </tr>
                        <tr>
                            <th colSpan="3">Po konferencji</th>
                        </tr>
                        <tr>
                            <td>19:00 - 22:00</td>
                            <td>Uroczysta kolacja</td>
                            <td>3h</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="agenda-container_subheader">
                <h2>27 września 2024 (Dzień 2)</h2>
            </div>
            <div className="agenda-container_description second_table">
            <table>
                    <thead>
                        <tr>
                            <th>Godziny</th>
                            <th>Element konferencji</th>
                            <th>Czas trwania</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>10:00 - 11:00</td>
                            <td>Sesja sponsorów</td>
                            <td>1h</td>
                        </tr>
                        <tr>
                            <td>11:00 - 12:30</td>
                            <td>Sesja referatów III</td>
                            <td>1h 30min</td>
                        </tr>
                        <tr>
                            <td>12:30 - 13:00</td>
                            <td>Przerwa kawowa</td>
                            <td>30min</td>
                        </tr>
                        <tr>
                            <td>13:00 - 14:00</td>
                            <td>Sesja posterowa</td>
                            <td>1h</td>
                        </tr>
                        <tr>
                            <td>14:00 - 15:00</td>
                            <td>Przerwa obiadowa</td>
                            <td>1h</td>
                        </tr>
                        <tr>
                            <td>15:00 - 16:30</td>
                            <td>Warsztaty</td>
                            <td>1h 30min</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div> 
    </div>
  )
}

export default Agenda
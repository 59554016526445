import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import App from './App';
import { Header, Footer } from './containers';
import { Navbar } from './components';
import { Abstracts } from './multisite';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <BrowserRouter>
        <div className="App">
            <span className="anchor-about" id="giswnauce_about"></span>
            <Header />
            <Navbar />
            <Routes>
                <Route path="" element={<React.StrictMode><App /></React.StrictMode>} />
                <Route path="giswnauce-abstracts" element={<React.StrictMode><Abstracts /></React.StrictMode>} />
            </Routes>
            <Footer />
        </div>
    </BrowserRouter>
);
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import BSZ2023 from '../../assets/komitet-naukowy/BSZ2023.webp';
import EB2023 from '../../assets/komitet-naukowy/EB2023.webp';
import EL2023 from '../../assets/komitet-naukowy/EL2023.webp';
import AJ2024 from '../../assets/komitet-naukowy/AJ2024.webp';
import IJ2023 from '../../assets/komitet-naukowy/IJ2023.webp';
import LG2023 from '../../assets/komitet-naukowy/LG2023.webp';
import MR2023 from '../../assets/komitet-naukowy/MR2023.webp';
import PW2023 from '../../assets/komitet-naukowy/PW2023.webp';
import PWE2023 from '../../assets/komitet-naukowy/PWE2023.webp';
import KK2023 from '../../assets/komitet-naukowy/KK2023.webp';
import MF2023 from '../../assets/komitet-naukowy/MF2023.webp';
import ZL2023 from '../../assets/komitet-naukowy/ZL2023.webp';
import JK2023 from '../../assets/komitet-naukowy/JK2023.webp';
import DG2023 from '../../assets/komitet-naukowy/DG2023.webp';
import DKZ2023 from '../../assets/komitet-naukowy/DKZ2023.webp';
import ZZ2023 from '../../assets/komitet-naukowy/ZZ2023.webp';
import AI2023 from '../../assets/komitet-organizacyjny/AI2023.webp';
import AS2023 from '../../assets/komitet-organizacyjny/AS2023.webp';
import DF2023 from '../../assets/komitet-organizacyjny/DF2023.webp';
import JJ2023 from '../../assets/komitet-organizacyjny/JJ2023.webp';
import KD2023 from '../../assets/komitet-organizacyjny/KD2023.webp';
/*import KM2023 from '../../assets/komitet-organizacyjny/KM2023.webp';*/
import TW2023 from '../../assets/komitet-organizacyjny/TW2023.webp';

import './committees.css';

const Committees = () => {
  return (
    <div className="giswnauce__committees">
        <div className="giswnauce__committees-container">
            <div className="committees-container_header">
                <p><FontAwesomeIcon icon={faPeopleGroup} /></p><h1>Komitety</h1>
            </div>
            <div className="committees-container_subheader">
                <span className="anchor-scientific-committee" id="giswnauce_scientific-committee"></span>
                <h2>Komitet Naukowy</h2>
            </div>
            <div className="committees-container_description">
                <ul>
                    <li>
                        <a className="committee-more" href="https://www.wig.wat.edu.pl/index.php/pracownicy/wizytowki/geodezja-i-kartografia/316-prof-dr-hab-inz-elzbieta-bielecka" target="_blank" rel="noreferrer"><img src={ EB2023 } alt="Elżbieta Bielecka" /></a>
                        <p><a href="https://www.wig.wat.edu.pl/index.php/pracownicy/wizytowki/geodezja-i-kartografia/316-prof-dr-hab-inz-elzbieta-bielecka" target="_blank" rel="noreferrer"><b>Prof. dr hab. inż. Elżbieta Bielecka</b></a><br />
                        Wydział Inżynierii Lądowej i&nbsp;Geodezji<br />
                        Wojskowa Akademia Techniczna, Warszawa</p>
                    </li>
                    <li>
                        <a className="committee-more" href="https://mostwiedzy.pl/pl/mariusz-figurski,1137185-1" target="_blank" rel="noreferrer"><img src={ MF2023 } alt="Mariusz Figurski" /></a>
                        <p><a href="https://mostwiedzy.pl/pl/mariusz-figurski,1137185-1" target="_blank" rel="noreferrer"><b>Prof. dr hab. inż. Mariusz Figurski</b></a><br />
                        Wydział Inżynierii Lądowej i&nbsp;Środowiska<br />
                        Politechnika Gdańska, Gdańsk</p>
                    </li>
                    <li>
                        <a className="committee-more" href="https://www.umcs.pl/pl/addres-book-employee,757,pl.html" target="_blank" rel="noreferrer"><img src={ LG2023 } alt="Leszek Gawrysiak" /></a>
                        <p><a href="https://www.umcs.pl/pl/addres-book-employee,757,pl.html" target="_blank" rel="noreferrer"><b>Dr hab. Leszek Gawrysiak</b></a><br />
                        Wydział Nauk o&nbsp;Ziemii i&nbsp;Gospodarki Przestrzennej<br />
                        Uniwersytet Marii Curie-Skłodowskiej, Lublin</p>
                    </li>
                    <li>
                        <a className="committee-more" href="https://repo.pw.edu.pl/info/author/WUT54633/Dariusz%2BGotlib?tab=&r=author&lang=pl" target="_blank" rel="noreferrer"><img src={ DG2023 } alt="Dariusz Gotlib" /></a>
                        <p><a href="https://repo.pw.edu.pl/info/author/WUT54633/Dariusz%2BGotlib?tab=&r=author&lang=pl" target="_blank" rel="noreferrer"><b>Dr hab. inż. Dariusz Gotlib</b></a><br />
                        Wydział Geodezji i&nbsp;Kartografii<br />
                        Politechnika Warszawska, Warszawa</p>
                    </li>
                    <li>
                        <a className="committee-more" href="https://bazawiedzy.uwm.edu.pl/info/author/UWM0bf7d6a2650c413c9232dc72a0528491?ps=20&tab=main&lang=pl&title=Person%2Bprofile%2B%25E2%2580%2593%2BArtur%2BJanowski%2B%25E2%2580%2593%2BUniversity%2Bof%2BWarmia%2Band%2BMazury&pn=1&cid=526266" target="_blank" rel="noreferrer"><img src={ AJ2024 } alt="Artur Janowski" /></a>
                        <p><a href="https://bazawiedzy.uwm.edu.pl/info/author/UWM0bf7d6a2650c413c9232dc72a0528491?ps=20&tab=main&lang=pl&title=Person%2Bprofile%2B%25E2%2580%2593%2BArtur%2BJanowski%2B%25E2%2580%2593%2BUniversity%2Bof%2BWarmia%2Band%2BMazury&pn=1&cid=526266" target="_blank" rel="noreferrer"><b>Dr hab. inż. Artur Janowski, prof. UWM</b></a><br />
                        Wydział Geoinżynierii<br />
                        Uniwersytet Warmińsko-Mazurski, Olsztyn</p>
                    </li>
                    <li>
                        <a className="committee-more" href="https://www.uni.lodz.pl/pracownicy/iwona-jazdzewska" target="_blank" rel="noreferrer"><img src={ IJ2023 } alt="Iwona Jażdżewska" /></a>
                        <p><a href="https://www.uni.lodz.pl/pracownicy/iwona-jazdzewska" target="_blank" rel="noreferrer"><b>Prof. dr hab. Iwona Jażdżewska</b></a><br />
                        Wydział Nauk Geograficznych<br />
                        Uniwersytet Łódzki, Łódź</p>
                    </li>
                    <li>
                        <a className="committee-more" href="https://mostwiedzy.pl/pl/dorota-kamrowska-zaluska,25027-1" target="_blank" rel="noreferrer"><img src={ DKZ2023 } alt="Dorota Dominika Kamrowska-Załuska" /></a>
                        <p><a href="https://mostwiedzy.pl/pl/dorota-kamrowska-zaluska,25027-1" target="_blank" rel="noreferrer"><b>Dr hab. inż. arch. Dorota Dominika Kamrowska-Załuska</b></a><br />
                        Wydział Architektury<br />
                        Politechnika Gdańska, Gdańsk</p>
                    </li>
                    <li>
                        <a className="committee-more" href="https://orcid.org/0000-0003-1001-8151" target="_blank" rel="noreferrer"><img src={ JK2023 } alt="Jacek Kozak" /></a>
                        <p><a href="https://orcid.org/0000-0003-1001-8151" target="_blank" rel="noreferrer"><b>Prof. dr hab. inż. Jacek Kozak</b></a><br />
                        Instytut Geografii i&nbsp;Gospodarki Przestrzennej<br />
                        Uniwersytet Jagieloński, Kraków</p>
                    </li>
                    <li>
                        <a className="committee-more" href="https://skos.agh.edu.pl/osoba/krystian-koziol-6626.html" target="_blank" rel="noreferrer"><img src={ KK2023 } alt="Krystian Kozioł" /></a>
                        <p><a href="https://skos.agh.edu.pl/osoba/krystian-koziol-6626.html" target="_blank" rel="noreferrer"><b>Dr hab. inż. Krystian Kozioł</b></a><br />
                        Wydział Geodezji Górniczej i&nbsp;Inżynierii Środowiska<br />
                        Akademia Górniczo-Hutnicza im.&nbsp;Stanisława Staszica, Kraków</p>
                    </li>
                    <li>
                        <a className="committee-more" href="https://omegatest.uwm.edu.pl/info/author/UWM369dfb7e8a8849718787b85a3347fbc8/" target="_blank" rel="noreferrer"><img src={ EL2023 } alt="Elżbieta Lewandowicz" /></a>
                        <p><a href="https://omegatest.uwm.edu.pl/info/author/UWM369dfb7e8a8849718787b85a3347fbc8/" target="_blank" rel="noreferrer"><b>Dr hab. inż. Elżbieta Lewandowicz</b></a><br />
                        Wydział Geoinżynierii<br />
                        Uniwersytet Warmińsko-Mazurski, Olsztyn</p>
                    </li>
                    <li>
                        <a className="committee-more" href="https://mostwiedzy.pl/pl/zbigniew-lubniewski,16638-1" target="_blank" rel="noreferrer"><img src={ ZL2023 } alt="Zbigniew Łubniewski" /></a>
                        <p><a href="https://mostwiedzy.pl/pl/zbigniew-lubniewski,16638-1" target="_blank" rel="noreferrer"><b>Dr hab. inż. Zbigniew Łubniewski</b></a><br />
                        Wydział Elektroniki, Telekomunikacji i&nbsp;Informatyki<br />
                        Politechnika Gdańska, Gdańsk</p>
                    </li>
                    <li>
                        <a className="committee-more" href="https://rzeszewski.org/" target="_blank" rel="noreferrer"><img src={ MR2023 } alt="Michał Rzeszewski" /></a>
                        <p><a href="https://rzeszewski.org/" target="_blank" rel="noreferrer"><b>Dr hab. Michał Rzeszewski</b></a><br />
                        Wydział Geografii Społeczno-Ekonomicznej i&nbsp;Gospodarki Przestrzennej<br />
                        Uniwersytet im. Adama Mickiewicza, Poznań</p>
                    </li>
                    <li>
                        <a className="committee-more" href="https://wiki.kul.pl/lhdb/Bogumi%c5%82_Szady" target="_blank" rel="noreferrer"><img src={ BSZ2023 } alt="Bogumił Szady" /></a>
                        <p><a href="https://wiki.kul.pl/lhdb/Bogumi%c5%82_Szady" target="_blank" rel="noreferrer"><b>Dr hab. Bogumił Szady</b></a><br />
                        Ośrodek Badań nad Geografią Historyczną Kościoła w&nbsp;Polsce<br />
                        Katolicki Uniwersytet Lubelski, Lublin<br />
                        Instytut Historii im. Tadeusza Manteuffla<br />
                        Polska Akademia Nauk, Warszawa</p>
                    </li>
                    <li>
                        <a className="committee-more" href="https://usosweb.uw.edu.pl/kontroler.php?_action=katalog2/osoby/pokazOsobe&os_id=50331" target="_blank" rel="noreferrer"><img src={ PWE2023 } alt="Piotr Werner" /></a>
                        <p><a href="https://usosweb.uw.edu.pl/kontroler.php?_action=katalog2/osoby/pokazOsobe&os_id=50331" target="_blank" rel="noreferrer"><b>Prof. dr hab. Piotr Werner</b></a><br />
                        Wydział Geografii i&nbsp;Studiów Regionalnych<br />
                        Uniwersytet Warszawski, Warszawa</p>
                    </li>
                    <li>
                        <a className="committee-more" href="https://urk.edu.pl/pracownicy.html/2881" target="_blank" rel="noreferrer"><img src={ PW2023 } alt="Piotr Wężyk" /></a>
                        <p><a href="https://urk.edu.pl/pracownicy.html/2881" target="_blank" rel="noreferrer"><b>Dr hab. inż. Piotr Wężyk</b></a><br />
                        Wydział Leśny<br />
                        Uniwersytet Rolniczy, Kraków</p>
                    </li>
                    <li>
                        <a className="committee-more" href="https://amu.edu.pl/dla-mediow/baza-ekspertow/klimat-i-ekologia/prof.-zbigniew-zwolinski" target="_blank" rel="noreferrer"><img src={ ZZ2023 } alt="Zbigniew Zwoliński" /></a>
                        <p><a href="https://amu.edu.pl/dla-mediow/baza-ekspertow/klimat-i-ekologia/prof.-zbigniew-zwolinski" target="_blank" rel="noreferrer"><b>Prof. dr hab. Zbigniew Zwoliński</b></a><br />
                        Zakład Geoinformacji<br />
                        Uniwersytet im. Adama Mickiewicza, Poznań</p>
                    </li>
                </ul>
            </div>
            <div className="committees-container_subheader">
                <span className="anchor-organizing-committee" id="giswnauce_organizing-committee"></span>
                <h2>Komitet Organizacyjny</h2>
            </div>
            <div className="committees-container_description">
                <h3>Przewodniczący</h3>
                <ul>
                    <li>
                        <a className="committee-more" href="https://mostwiedzy.pl/pl/adam-inglot,1017456-1?" target="_blank" rel="noreferrer"><img src={ AI2023 } alt="Adam Inglot" /></a>
                        <p><a href="https://mostwiedzy.pl/pl/adam-inglot,1017456-1?" target="_blank" rel="noreferrer"><b>Dr inż. Adam Inglot</b></a><br />
                        Wydział Inżynierii Lądowej i&nbsp;Środowiska<br />
                        Politechnika Gdańska, Gdańsk</p>
                    </li>
                </ul>
                <h3>Członkowie</h3>
                <ul>
                    <li>
                        <a className="committee-more" href="https://mostwiedzy.pl/pl/karol-daliga,165138-1?" target="_blank" rel="noreferrer"><img src={ KD2023 } alt="Karol Daliga" /></a>
                        <p><a href="https://mostwiedzy.pl/pl/karol-daliga,165138-1?" target="_blank" rel="noreferrer"><b>Dr inż. Karol Daliga</b></a><br />
                        Wydział Inżynierii Lądowej i&nbsp;Środowiska<br />
                        Politechnika Gdańska, Gdańsk</p>
                    </li>
                    <li>
                        <a className="committee-more" href="https://mostwiedzy.pl/pl/daria-filipiak-kowszyk,944542-1?" target="_blank" rel="noreferrer"><img src={ DF2023 } alt="Daria Filipiak-Kowszyk" /></a>
                        <p><a href="https://mostwiedzy.pl/pl/daria-filipiak-kowszyk,944542-1?" target="_blank" rel="noreferrer"><b>Dr inż. Daria Filipiak-Kowszyk</b></a><br />
                        Wydział Inżynierii Lądowej i&nbsp;Środowiska<br />
                        Politechnika Gdańska, Gdańsk</p>
                    </li>
                    {/*<li>
                        <a className="committee-more" href="https://mostwiedzy.pl/pl/krystyna-michalowska,1226526-1" target="_blank" rel="noreferrer"><img src={ KM2023 } alt="Krystyna Michałowska" /></a>
                        <p><a href="https://mostwiedzy.pl/pl/krystyna-michalowska,1226526-1" target="_blank" rel="noreferrer"><b>Dr inż. Krystyna Michałowska</b></a><br />
                        Wydział Inżynierii Lądowej i&nbsp;Środowiska<br />
                        Politechnika Gdańska, Gdańsk</p>
                    </li>*/}
                    <li>
                        <a className="committee-more" href="https://mostwiedzy.pl/pl/anna-sobieraj-zlobinska,741129-1?" target="_blank" rel="noreferrer"><img src={ AS2023 } alt="Anna Sobieraj-Żłobińska" /></a>
                        <p><a href="https://mostwiedzy.pl/pl/anna-sobieraj-zlobinska,741129-1?" target="_blank" rel="noreferrer"><b>Dr inż. Anna Sobieraj-Żłobińska</b></a><br />
                        Wydział Inżynierii Lądowej i&nbsp;Środowiska<br />
                        Politechnika Gdańska, Gdańsk</p>
                    </li>
                    <li>
                        <a className="committee-more" href="https://mostwiedzy.pl/pl/tadeusz-widerski,14001-1?" target="_blank" rel="noreferrer"><img src={ TW2023 } alt="Tadeusz Widerski" /></a>
                        <p><a href="https://mostwiedzy.pl/pl/tadeusz-widerski,14001-1?" target="_blank" rel="noreferrer"><b>Dr inż. Tadeusz Widerski</b></a><br />
                        Wydział Inżynierii Lądowej i&nbsp;Środowiska<br />
                        Politechnika Gdańska, Gdańsk</p>
                    </li>
                </ul>
                <h3>Multimedia</h3>
                <ul>
                    <li>
                        <a className="committee-more" href="https://mostwiedzy.pl/pl/jakub-jablonski,1166710-1" target="_blank" rel="noreferrer"><img src={ JJ2023 } alt="Jakub Jabłoński" /></a>
                        <p><a href="https://mostwiedzy.pl/pl/jakub-jablonski,1166710-1" target="_blank" rel="noreferrer"><b>Inż. Jakub Jabłoński</b></a><br />
                        Wydział Inżynierii Lądowej i&nbsp;Środowiska<br />
                        Politechnika Gdańska, Gdańsk</p>
                    </li>
                </ul>
            </div>
        </div> 
    </div>
  )
}

export default Committees
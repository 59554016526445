import React, { useEffect } from 'react';
import { Header, Main, Right, Committees, Agenda, Participation, Place, Contact, Footer } from './containers';
import { Navbar } from './components';

import './App.css';

const App = () => {
  const handleScroll = () => {
    const position = window.pageYOffset;
    let elem = document.getElementsByClassName("sticky-navbar")[0];
    let rect = elem.getBoundingClientRect().top;
    if(position > (rect + window.scrollY)) {
      document.getElementsByClassName("giswnauce__navbar")[0].classList.add("scroll-nav");
      document.getElementsByClassName("giswnauce__main")[0].classList.add("scroll-margin");
      document.getElementsByClassName("giswnauce__col-right")[0].classList.add("scroll-rightmargin");
      document.getElementsByClassName("giswnauce__navbar-committees-dropdown")[0].classList.add("scroll-committees-dropdown-fixed");
      document.getElementsByClassName("giswnauce__navbar-committees-dropdown")[0].classList.remove("scroll-committees-dropdown-absolute");
      if(document.getElementsByClassName("giswnauce__navbar-menu_cont")[0] !== undefined){
        document.getElementsByClassName("giswnauce__navbar-menu_cont")[0].classList.add("scroll-dropdown-fixed");
        document.getElementsByClassName("giswnauce__navbar-menu_cont")[0].classList.remove("scroll-dropdown-absolute");
      }
    }
    else {
      document.getElementsByClassName("giswnauce__navbar")[0].classList.remove("scroll-nav");
      document.getElementsByClassName("giswnauce__main")[0].classList.remove("scroll-margin");
      document.getElementsByClassName("giswnauce__col-right")[0].classList.remove("scroll-rightmargin");
      document.getElementsByClassName("giswnauce__navbar-committees-dropdown")[0].classList.remove("scroll-committees-dropdown-fixed");
      document.getElementsByClassName("giswnauce__navbar-committees-dropdown")[0].classList.add("scroll-committees-dropdown-absolute");
      if(document.getElementsByClassName("giswnauce__navbar-menu_cont")[0] !== undefined){
        document.getElementsByClassName("giswnauce__navbar-menu_cont")[0].classList.remove("scroll-dropdown-fixed");
        document.getElementsByClassName("giswnauce__navbar-menu_cont")[0].classList.add("scroll-dropdown-absolute");
      }
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className="App">
        {/*<span className="anchor-about" id="giswnauce_about"></span>*/}
        {/* <Header /> */}
        {/* <Navbar /> */}
        {/* <Footer /> */}
        <div className="giswnauce__col">
          <div className="giswnauce__col-left">
            <Main />
            <Committees />
            <Agenda />
            <Participation />
            <Place />
            <Contact />
            
          </div>
          <div className="giswnauce__col-right">
            <Right />
          </div>
        </div>
    </div>
  )
}

export default App
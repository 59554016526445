import React from 'react';
import LogoKG from '../../assets/LogotypKG.webp';
import EsriPolska from '../../assets/Esri_Polska.png';
import Blank from '../../assets/Blank.webp';

import './right.css';

const Right = () => {
  return (
    <div className="giswnauce__right">
        <div className="giswnauce__right-event_organizer">
            <div className="event_organizer-header">
                <h1>Organizator</h1>
            </div>
            <div className="event_organizer-items">
                <a className="organizer-items-logo" href="https://wilis.pg.edu.pl/kg" target="_blank" rel="noreferrer"><img src={ LogoKG } alt="Katedra Geodezji" /></a>
            </div>
        </div>
        <div className="giswnauce__right-event_organizer">
            <div className="event_organizer-header">
                <h1>Partner główny</h1>
            </div>
            <div className="event_organizer-items">
                <a className="organizer-items-logo-esri" href="https://www.esri.pl" target="_blank" rel="noreferrer"><img src={ EsriPolska } alt="Esri Polska" /></a>
            </div>
        </div>
        {/*<div className="giswnauce__right-event_organizer">
            <div className="event_organizer-header">
                <h1>Patronat honorowy</h1>
            </div>
            <div className="event_organizer-items">
                <a className="organizer-items-logo" href="https://giswnauce.org" target="_blank" rel="noreferrer"><img src={ Blank } alt="Katedra Geodezji" /></a>
            </div>
        </div>
        <div className="giswnauce__right-event_organizer">
            <div className="event_organizer-header">
                <h1>Patronat medialny</h1>
            </div>
            <div className="event_organizer-items">
                <a className="organizer-items-logo" href="https://giswnauce.org" target="_blank" rel="noreferrer"><img src={ Blank } alt="Katedra Geodezji" /></a>
            </div>
        </div>
        <div className="giswnauce__right-event_organizer">
            <div className="event_organizer-header">
                <h1>Partner oficjalny</h1>
            </div>
            <div className="event_organizer-items">
                <a className="organizer-items-logo" href="https://giswnauce.org" target="_blank" rel="noreferrer"><img src={ Blank } alt="Katedra Geodezji" /></a>
            </div>
        </div>*/}
    </div>
  )
}

export default Right
import React, { useState, useEffect } from 'react';
import { BurgerClose } from 'react-burger-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import './navbar.css';

const Navbar = () => {
    const [isClosed, setIsClosed] = useState(false);
    const [isCommitteesClosed, setIsCommitteesClosed] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // Funkcja do przewijania po załadowaniu strony
    const scrollToAnchor = (anchor) => {
        const element = document.getElementById(anchor);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Przewijaj do kotwicy po załadowaniu strony
    useEffect(() => {
        if (location.hash) {
            const anchor = location.hash.substring(1); // Usuwa znak # z hash
            scrollToAnchor(anchor);
        }
    }, [location]);

    const handleNavigation = (anchor) => {
        setIsClosed(false);
        setIsCommitteesClosed(false);
        navigate(`/#${anchor}`);
    };

    /*const handleSubpageNavigation = (path) => {
        setIsClosed(false);
        setIsCommitteesClosed(false);
        navigate(path);
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    };*/

    return (
        <div>
            <div className="giswnauce__navbar">
                <div className="giswnauce__navbar-links">
                    <p id="about" onClick={() => handleNavigation('giswnauce_about')}>O konferencji<span id="line1" /></p>
                    <p id="committees" onClick={() => setIsCommitteesClosed(!isCommitteesClosed)}>Komitety<span id="line2" /></p>
                    <p id="agenda" onClick={() => handleNavigation('giswnauce_agenda')}>Program<span id="line3" /></p>
                    <p id="participation" onClick={() => handleNavigation('giswnauce_participation')}>Uczestnictwo<span id="line4" /></p>
                    <p id="place" onClick={() => handleNavigation('giswnauce_place')}>Miejsce<span id="line5" /></p>
                    <p id="contact" onClick={() => handleNavigation('giswnauce_contact')}>Kontakt<span id="line6" /></p>
                    {/*<p id="gallery" onClick={() => handleNavigation('giswnauce_gallery')}>Galeria<span id="line7" /></p>
                    <p id="abstracts" onClick={() => handleSubpageNavigation('/giswnauce-abstracts')}>Abstrakty<span id="line7" /></p>*/}
                </div>
                <div className="giswnauce__navbar-menubtn">
                    <button className="giswnauce__navbar-menubtn_button" onClick={() => setIsClosed(!isClosed)} style={{width: "2rem", height: "2rem", display: "grid", placeItems: "center",}}>
                        <BurgerClose isClosed={isClosed} />
                    </button>
                </div>
            </div>

            <div className="giswnauce__navbar-committees-dropdown">
                {isCommitteesClosed && (
                    <div id="committees-dropdown-menu" className="committees-dropdown-menu scale-up-ver-top">
                        <p id="scientific-committee" onClick={() => handleNavigation('giswnauce_scientific-committee')}>Komitet Naukowy</p>
                        <p id="organizing-committee" onClick={() => handleNavigation('giswnauce_organizing-committee')}>Komitet Organizacyjny</p>
                    </div>
                )}
            </div>

            <div className="giswnauce__navbar-menu_cont scroll-dropdown-absolute">
                {isClosed && (
                    <div id="dropdown-menu" className="giswnauce__navbar-menu scale-up-ver-top">
                        <p id="about" onClick={() => handleNavigation('giswnauce_about')}>O konferencji</p>
                        <p id="scientific-committee" onClick={() => handleNavigation('giswnauce_scientific-committee')}>Komitet Naukowy</p>
                        <p id="organizing-committee" onClick={() => handleNavigation('giswnauce_organizing-committee')}>Komitet Organizacyjny</p>
                        <p id="agenda" onClick={() => handleNavigation('giswnauce_agenda')}>Program</p>
                        <p id="participation" onClick={() => handleNavigation('giswnauce_participation')}>Uczestnictwo</p>
                        <p id="place" onClick={() => handleNavigation('giswnauce_place')}>Miejsce</p>
                        <p id="contact" onClick={() => handleNavigation('giswnauce_contact')}>Kontakt</p>
                        {/*<p id="gallery" onClick={() => handleNavigation('giswnauce_gallery')}>Galeria</p>
                        <p id="abstracts" onClick={() => handleSubpageNavigation('/giswnauce-abstracts')}>Abstrakty</p>*/}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Navbar;
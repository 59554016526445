import React, { useEffect, useState, useMemo } from 'react';

import './abstracts.css';

const Abstracts = () => {
  const handleScroll = () => {
    const position = window.pageYOffset;
    let elem = document.getElementsByClassName("sticky-navbar")[0];
    let rect = elem.getBoundingClientRect().top;
    if(position > (rect + window.scrollY)) {
      document.getElementsByClassName("giswnauce__navbar")[0].classList.add("scroll-nav");
      document.getElementsByClassName("giswnauce__abstracts")[0].classList.add("scroll-margin");
      /*document.getElementsByClassName("giswnauce__col-right")[0].classList.add("scroll-rightmargin");*/
      document.getElementsByClassName("giswnauce__navbar-committees-dropdown")[0].classList.add("scroll-committees-dropdown-fixed");
      document.getElementsByClassName("giswnauce__navbar-committees-dropdown")[0].classList.remove("scroll-committees-dropdown-absolute");
      if(document.getElementsByClassName("giswnauce__navbar-menu_cont")[0] !== undefined){
        document.getElementsByClassName("giswnauce__navbar-menu_cont")[0].classList.add("scroll-dropdown-fixed");
        document.getElementsByClassName("giswnauce__navbar-menu_cont")[0].classList.remove("scroll-dropdown-absolute");
      }
    }
    else {
      document.getElementsByClassName("giswnauce__navbar")[0].classList.remove("scroll-nav");
      document.getElementsByClassName("giswnauce__abstracts")[0].classList.remove("scroll-margin");
      /*document.getElementsByClassName("giswnauce__col-right")[0].classList.remove("scroll-rightmargin");*/
      document.getElementsByClassName("giswnauce__navbar-committees-dropdown")[0].classList.remove("scroll-committees-dropdown-fixed");
      document.getElementsByClassName("giswnauce__navbar-committees-dropdown")[0].classList.add("scroll-committees-dropdown-absolute");
      if(document.getElementsByClassName("giswnauce__navbar-menu_cont")[0] !== undefined){
        document.getElementsByClassName("giswnauce__navbar-menu_cont")[0].classList.remove("scroll-dropdown-fixed");
        document.getElementsByClassName("giswnauce__navbar-menu_cont")[0].classList.add("scroll-dropdown-absolute");
      }
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

const tiles = useMemo(() => [
  { id: 1, room: 1, date: '2024-08-23', startTime: '11:30', endTime: '11:45', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 2, room: 2, date: '2024-08-23', startTime: '11:30', endTime: '11:45', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 3, room: 1, date: '2024-08-23', startTime: '11:45', endTime: '12:00', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 4, room: 2, date: '2024-08-23', startTime: '11:45', endTime: '12:00', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 5, room: 1, date: '2024-08-23', startTime: '12:00', endTime: '12:15', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 6, room: 2, date: '2024-08-23', startTime: '12:00', endTime: '12:15', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 7, room: 1, date: '2024-08-23', startTime: '12:15', endTime: '12:30', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 8, room: 2, date: '2024-08-23', startTime: '12:15', endTime: '12:30', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 9, room: 1, date: '2024-08-23', startTime: '12:30', endTime: '12:45', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 10, room: 2, date: '2024-08-23', startTime: '12:30', endTime: '12:45', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 11, room: 1, date: '2024-08-23', startTime: '12:45', endTime: '13:00', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 12, room: 2, date: '2024-08-23', startTime: '12:45', endTime: '13:00', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 13, room: 1, date: '2024-08-23', startTime: '13:00', endTime: '13:15', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 14, room: 2, date: '2024-08-23', startTime: '13:00', endTime: '13:15', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 15, room: 1, date: '2024-08-23', startTime: '13:15', endTime: '13:30', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 16, room: 2, date: '2024-08-23', startTime: '13:15', endTime: '13:30', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 17, room: 1, date: '2024-08-23', startTime: '13:30', endTime: '13:45', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 18, room: 2, date: '2024-08-23', startTime: '13:30', endTime: '13:45', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 19, room: 1, date: '2024-08-23', startTime: '13:45', endTime: '14:00', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 20, room: 2, date: '2024-08-23', startTime: '13:45', endTime: '14:00', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 21, room: 1, date: '2024-08-23', startTime: '14:45', endTime: '15:00', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 22, room: 2, date: '2024-08-23', startTime: '14:45', endTime: '15:00', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 23, room: 1, date: '2024-08-23', startTime: '15:00', endTime: '15:15', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 24, room: 2, date: '2024-08-23', startTime: '15:00', endTime: '15:15', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 25, room: 1, date: '2024-08-23', startTime: '15:15', endTime: '15:30', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 26, room: 2, date: '2024-08-23', startTime: '15:15', endTime: '15:30', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 27, room: 1, date: '2024-08-23', startTime: '15:30', endTime: '15:45', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 28, room: 2, date: '2024-08-23', startTime: '15:30', endTime: '15:45', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 29, room: 1, date: '2024-08-23', startTime: '15:45', endTime: '16:00', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 30, room: 2, date: '2024-08-23', startTime: '15:45', endTime: '16:00', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 31, room: 1, date: '2024-08-23', startTime: '16:00', endTime: '16:15', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 32, room: 2, date: '2024-08-23', startTime: '16:00', endTime: '16:15', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 33, room: 1, date: '2024-08-23', startTime: '16:15', endTime: '16:30', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 34, room: 2, date: '2024-08-23', startTime: '16:15', endTime: '16:30', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 35, room: 1, date: '2024-08-23', startTime: '16:30', endTime: '16:45', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 36, room: 2, date: '2024-08-23', startTime: '16:30', endTime: '16:45', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 37, room: 1, date: '2024-08-23', startTime: '16:45', endTime: '17:00', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 38, room: 2, date: '2024-08-23', startTime: '16:45', endTime: '17:00', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 39, room: 1, date: '2024-08-24', startTime: '10:00', endTime: '10:15', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 40, room: 2, date: '2024-08-24', startTime: '10:00', endTime: '10:15', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 41, room: 1, date: '2024-08-24', startTime: '10:15', endTime: '10:30', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 42, room: 2, date: '2024-08-24', startTime: '10:15', endTime: '10:30', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 43, room: 1, date: '2024-08-24', startTime: '10:30', endTime: '10:45', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 44, room: 2, date: '2024-08-24', startTime: '10:30', endTime: '10:45', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 45, room: 1, date: '2024-08-24', startTime: '10:45', endTime: '11:00', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 46, room: 2, date: '2024-08-24', startTime: '10:45', endTime: '11:00', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 47, room: 1, date: '2024-08-24', startTime: '11:00', endTime: '11:15', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 48, room: 2, date: '2024-08-24', startTime: '11:00', endTime: '11:15', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 49, room: 1, date: '2024-08-24', startTime: '11:15', endTime: '11:30', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 50, room: 2, date: '2024-08-24', startTime: '11:15', endTime: '11:30', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 51, room: 1, date: '2024-08-24', startTime: '11:30', endTime: '11:45', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 52, room: 2, date: '2024-08-24', startTime: '11:30', endTime: '11:45', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 53, room: 1, date: '2024-08-24', startTime: '11:45', endTime: '12:00', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 54, room: 2, date: '2024-08-24', startTime: '11:45', endTime: '12:00', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 55, room: 1, date: '2024-08-24', startTime: '12:00', endTime: '12:15', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 56, room: 2, date: '2024-08-24', startTime: '12:00', endTime: '12:15', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 57, room: 1, date: '2024-08-24', startTime: '12:15', endTime: '12:30', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 58, room: 2, date: '2024-08-24', startTime: '12:15', endTime: '12:30', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 59, room: 1, date: '2024-08-24', startTime: '13:00', endTime: '13:15', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 60, room: 2, date: '2024-08-24', startTime: '13:00', endTime: '13:15', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 61, room: 1, date: '2024-08-24', startTime: '13:15', endTime: '13:30', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 62, room: 2, date: '2024-08-24', startTime: '13:15', endTime: '13:30', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 63, room: 1, date: '2024-08-24', startTime: '13:30', endTime: '13:45', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 64, room: 2, date: '2024-08-24', startTime: '13:30', endTime: '13:45', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 65, room: 1, date: '2024-08-24', startTime: '13:45', endTime: '14:00', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 66, room: 2, date: '2024-08-24', startTime: '13:45', endTime: '14:00', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 67, room: 1, date: '2024-08-24', startTime: '15:00', endTime: '15:15', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 68, room: 2, date: '2024-08-24', startTime: '15:00', endTime: '15:15', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 69, room: 1, date: '2024-08-24', startTime: '15:15', endTime: '15:30', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 70, room: 2, date: '2024-08-24', startTime: '15:15', endTime: '15:30', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 71, room: 1, date: '2024-08-24', startTime: '15:30', endTime: '15:45', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 72, room: 2, date: '2024-08-24', startTime: '15:30', endTime: '15:45', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 73, room: 1, date: '2024-08-24', startTime: '15:45', endTime: '16:00', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 74, room: 2, date: '2024-08-24', startTime: '15:45', endTime: '16:00', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 75, room: 1, date: '2024-08-24', startTime: '16:00', endTime: '16:15', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 76, room: 2, date: '2024-08-24', startTime: '16:00', endTime: '16:15', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 77, room: 1, date: '2024-08-24', startTime: '16:15', endTime: '16:30', name: 'Imię1', surname: 'Nazwisko1', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
  { id: 78, room: 2, date: '2024-08-24', startTime: '16:15', endTime: '16:30', name: 'Imię2', surname: 'Nazwisko2', institutuion: 'Uczelnia / Instytut', abstract: 'Tytuł referatu / prezentacji / posteru'},
], []);

const [liveTiles, setLiveTiles] = useState([]);

useEffect(() => {
  function updateLiveTiles() {
      const now = new Date();
      const updatedLiveTiles = tiles.filter(tile => {
          const tileDate = new Date(tile.date);
          const startTime = new Date(tileDate.setHours(...tile.startTime.split(':')));
          const endTime = new Date(tileDate.setHours(...tile.endTime.split(':')));

          return now >= startTime && now <= endTime;
      });

      setLiveTiles(updatedLiveTiles);
  }

  updateLiveTiles(); // Sprawdź na początku

  const intervalId = setInterval(updateLiveTiles, 60000); // Sprawdzaj co minutę
  return () => clearInterval(intervalId); // Wyczyszczenie interwału przy odmontowaniu komponentu
}, [tiles]);

  return (
    <div className="giswnauce__abstracts">
      <div className="live-content-two-rows">
        <div className="live-two-rows-container">
          <h1>Sala 1</h1>
          <div className="live-content_element-container">
            {liveTiles
              .filter(tile => tile.room === 1)
              .map(tile => (
              <div key={tile.id} className="content_element live">
                <h2>{tile.startTime}-{tile.endTime} — {tile.name} {tile.surname}</h2>
                <h3>{tile.institutuion}</h3>
                <p>{tile.abstract}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="live-two-rows-container">
          <h1>Sala 2</h1>
          <div className="live-content_element-container">
            {liveTiles
              .filter(tile => tile.room === 2)
              .map(tile => (
              <div key={tile.id} className="content_element live">
                <h2>{tile.startTime}-{tile.endTime} — {tile.name} {tile.surname}</h2>
                <h3>{tile.institutuion}</h3>
                <p>{tile.abstract}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="abstracts-container_header">
        <h1>Czwartek, 26 września</h1>
      </div>
      <div className="abstracts-container_subheader">
        <h2>10:00-11:00 — Uroczyste rozpoczęcie</h2>
      </div>
      <div className="giswnauce__abstracts-content-1">
        <div className="content_element-1">
          <h2>10:00-11:00 — Adam Inglot</h2>
          <h3>Politechnika Gdańska</h3>
          <p>Uroczyste rozpoczęcie, prezentacja otwierająca konferencję GIS w Nauce.</p>
        </div>
      </div>
      <div className="abstracts-container_subheader">
        <h2>11:00-11:30 — Przerwa kawowa</h2>
      </div>
      <div className="abstracts-container_subheader">
        <h2>11:30-14:00 — Sesja referatów I</h2>
      </div>
      <div className="content-two-rows">
        <div className="two-rows-container">
          <h1>Sala 1</h1>
          <div id="content_element-container" className="giswnauce__abstracts-content">
            {tiles
              .filter(tile => tile.room === 1 && tile.id <= 20)
              .map(tile => (
                <div
                  key={tile.id}
                  className={`content_element ${liveTiles.some(liveTile => liveTile.id === tile.id) ? 'live' : ''}`}
                >
                  <h2>{tile.startTime}-{tile.endTime} — {tile.name} {tile.surname}</h2>
                  <h3>{tile.institutuion}</h3>
                  <p>{tile.abstract}</p>
                </div>
              ))}
          </div>
        </div>
        <div className="two-rows-container">
          <h1>Sala 2</h1>
          <div id="content_element-container" className="giswnauce__abstracts-content">
            {tiles
              .filter(tile => tile.room === 2 && tile.id <= 20)
              .map(tile => (
                <div
                  key={tile.id}
                  className={`content_element ${liveTiles.some(liveTile => liveTile.id === tile.id) ? 'live' : ''}`}
                >
                  <h2>{tile.startTime}-{tile.endTime} — {tile.name} {tile.surname}</h2>
                  <h3>{tile.institutuion}</h3>
                  <p>{tile.abstract}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="abstracts-container_subheader">
        <h2>14:00-14:45 — Przerwa obiadowa</h2>
      </div>
      <div className="abstracts-container_subheader">
        <h2>14:45-17:00 — Sesja referatów II</h2>
      </div>
      <div className="content-two-rows">
        <div className="two-rows-container">
          <h1>Sala 1</h1>
          <div id="content_element-container" className="giswnauce__abstracts-content">
            {tiles
              .filter(tile => tile.room === 1 && (tile.id > 20 && tile.id <=38))
              .map(tile => (
                <div
                  key={tile.id}
                  className={`content_element ${liveTiles.some(liveTile => liveTile.id === tile.id) ? 'live' : ''}`}
                >
                  <h2>{tile.startTime}-{tile.endTime} — {tile.name} {tile.surname}</h2>
                  <h3>{tile.institutuion}</h3>
                  <p>{tile.abstract}</p>
                </div>
              ))}
          </div>
        </div>
        <div className="two-rows-container">
          <h1>Sala 2</h1>
          <div id="content_element-container" className="giswnauce__abstracts-content">
            {tiles
              .filter(tile => tile.room === 2 && (tile.id > 20 && tile.id <=38))
              .map(tile => (
                <div
                  key={tile.id}
                  className={`content_element ${liveTiles.some(liveTile => liveTile.id === tile.id) ? 'live' : ''}`}
                >
                  <h2>{tile.startTime}-{tile.endTime} — {tile.name} {tile.surname}</h2>
                  <h3>{tile.institutuion}</h3>
                  <p>{tile.abstract}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="abstracts-container_subheader">
        <h2>19:00-22:00 — Uroczysta kolacja</h2>
      </div>
      <div className="abstracts-container_header">
        <h1>Piątek, 27 września</h1>
      </div>
      <div className="abstracts-container_subheader">
        <h2>10:00-11:00 — Sesja sponsorów</h2>
      </div>
      <div className="content-two-rows">
        <div className="two-rows-container">
          <h1>Sala 1</h1>
          <div id="content_element-container" className="giswnauce__abstracts-content">
            {tiles
              .filter(tile => tile.room === 1 && (tile.id > 38 && tile.id <=46))
              .map(tile => (
                <div
                  key={tile.id}
                  className={`content_element ${liveTiles.some(liveTile => liveTile.id === tile.id) ? 'live' : ''}`}
                >
                  <h2>{tile.startTime}-{tile.endTime} — {tile.name} {tile.surname}</h2>
                  <h3>{tile.institutuion}</h3>
                  <p>{tile.abstract}</p>
                </div>
              ))}
          </div>
        </div>
        <div className="two-rows-container">
          <h1>Sala 2</h1>
          <div id="content_element-container" className="giswnauce__abstracts-content">
            {tiles
              .filter(tile => tile.room === 2 && (tile.id > 38 && tile.id <=46))
              .map(tile => (
                <div
                  key={tile.id}
                  className={`content_element ${liveTiles.some(liveTile => liveTile.id === tile.id) ? 'live' : ''}`}
                >
                  <h2>{tile.startTime}-{tile.endTime} — {tile.name} {tile.surname}</h2>
                  <h3>{tile.institutuion}</h3>
                  <p>{tile.abstract}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="abstracts-container_subheader">
        <h2>11:00-12:30 — Sesja referatów III</h2>
      </div>
      <div className="content-two-rows">
        <div className="two-rows-container">
          <h1>Sala 1</h1>
          <div id="content_element-container" className="giswnauce__abstracts-content">
            {tiles
              .filter(tile => tile.room === 1 && (tile.id > 46 && tile.id <=58))
              .map(tile => (
                <div
                  key={tile.id}
                  className={`content_element ${liveTiles.some(liveTile => liveTile.id === tile.id) ? 'live' : ''}`}
                >
                  <h2>{tile.startTime}-{tile.endTime} — {tile.name} {tile.surname}</h2>
                  <h3>{tile.institutuion}</h3>
                  <p>{tile.abstract}</p>
                </div>
              ))}
          </div>
        </div>
        <div className="two-rows-container">
          <h1>Sala 2</h1>
          <div id="content_element-container" className="giswnauce__abstracts-content">
            {tiles
              .filter(tile => tile.room === 2 && (tile.id > 46 && tile.id <=58))
              .map(tile => (
                <div
                  key={tile.id}
                  className={`content_element ${liveTiles.some(liveTile => liveTile.id === tile.id) ? 'live' : ''}`}
                >
                  <h2>{tile.startTime}-{tile.endTime} — {tile.name} {tile.surname}</h2>
                  <h3>{tile.institutuion}</h3>
                  <p>{tile.abstract}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="abstracts-container_subheader">
        <h2>12:30-13:00 — Przerwa kawowa</h2>
      </div>
      <div className="abstracts-container_subheader">
        <h2>13:00-14:00 — Sesja posterowa</h2>
      </div>
      <div className="content-two-rows">
        <div className="two-rows-container">
          <h1>Sala 1</h1>
          <div id="content_element-container" className="giswnauce__abstracts-content">
            {tiles
              .filter(tile => tile.room === 1 && (tile.id > 58 && tile.id <=66))
              .map(tile => (
                <div
                  key={tile.id}
                  className={`content_element ${liveTiles.some(liveTile => liveTile.id === tile.id) ? 'live' : ''}`}
                >
                  <h2>{tile.startTime}-{tile.endTime} — {tile.name} {tile.surname}</h2>
                  <h3>{tile.institutuion}</h3>
                  <p>{tile.abstract}</p>
                </div>
              ))}
          </div>
        </div>
        <div className="two-rows-container">
          <h1>Sala 2</h1>
          <div id="content_element-container" className="giswnauce__abstracts-content">
            {tiles
              .filter(tile => tile.room === 2 && (tile.id > 58 && tile.id <=66))
              .map(tile => (
                <div
                  key={tile.id}
                  className={`content_element ${liveTiles.some(liveTile => liveTile.id === tile.id) ? 'live' : ''}`}
                >
                  <h2>{tile.startTime}-{tile.endTime} — {tile.name} {tile.surname}</h2>
                  <h3>{tile.institutuion}</h3>
                  <p>{tile.abstract}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="abstracts-container_subheader">
        <h2>14:00-15:00 — Przerwa obiadowa</h2>
      </div>
      <div className="abstracts-container_subheader">
        <h2>15:00-16:30 — Warsztaty</h2>
      </div>
      <div className="content-two-rows">
        <div className="two-rows-container">
          <h1>Sala 1</h1>
          <div id="content_element-container" className="giswnauce__abstracts-content">
            {tiles
              .filter(tile => tile.room === 1 && (tile.id > 66 && tile.id <=80))
              .map(tile => (
                <div
                  key={tile.id}
                  className={`content_element ${liveTiles.some(liveTile => liveTile.id === tile.id) ? 'live' : ''}`}
                >
                  <h2>{tile.startTime}-{tile.endTime} — {tile.name} {tile.surname}</h2>
                  <h3>{tile.institutuion}</h3>
                  <p>{tile.abstract}</p>
                </div>
              ))}
          </div>
        </div>
        <div className="two-rows-container">
          <h1>Sala 2</h1>
          <div id="content_element-container" className="giswnauce__abstracts-content">
            {tiles
              .filter(tile => tile.room === 2 && (tile.id > 66 && tile.id <=80))
              .map(tile => (
                <div
                  key={tile.id}
                  className={`content_element ${liveTiles.some(liveTile => liveTile.id === tile.id) ? 'live' : ''}`}
                >
                  <h2>{tile.startTime}-{tile.endTime} — {tile.name} {tile.surname}</h2>
                  <h3>{tile.institutuion}</h3>
                  <p>{tile.abstract}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Abstracts